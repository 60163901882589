export const pricings = [
  {
    title: 'Standard License',
    subtitle: 'A pay-once license, just for you',
    monthly: 49,
    annual: 480,
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  },
  {
    title: 'Extended License',
    subtitle: 'A pay-once license, just for you',
    monthly: 79,
    annual: 780,
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: true,
  },
  {
    title: 'Standard License +',
    subtitle: 'A pay-once license, just for you',
    monthly: 69,
    annual: 680,
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  },
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-2',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-3',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-4',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-5',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-6',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
];

export const plans = [
  {
    title: 'Move deals through your funnel – fast',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'On demand services right at your front-door',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'Online 24/7 support',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
];
